<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />

        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <section class="min-vh-100">
                <section class="form box">
                    <form @submit.prevent="newAccompagnement">
                        <div class="form-row justify-content-center">
                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Site <span class="text-danger">*</span></label>
                                    <input
                                        id="url"
                                        v-model="form.url"
                                        type="url"
                                        name="url"
                                        placeholder="https://exemple.fr"
                                        class="form-control"
                                        required
                                    />
                                    <span v-if="!urlValid && form.url" class="text-danger" style="font-size: 0.7rem"
                                        >Veuillez entrer un url valide (https://exemple.fr)</span
                                    >
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Catégorie du Site <span class="text-danger">*</span></label>
                                    <select v-model="form.groupe_id" class="form-control" required>
                                        <option :value="null" selected>-</option>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">
                                            <div v-if="group.parent_id">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}
                                            </div>

                                            <div v-else>
                                                {{ group.name }}
                                            </div>
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3 form-row p-0">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label"
                                            >Durée de l'accompagnement <span class="text-danger">*</span></label
                                        >
                                        <select v-model="form.duree" class="form-control" required>
                                            <option :value="null" selected>-</option>
                                            <option
                                                v-for="(duration, index) in durations"
                                                :key="index"
                                                :value="duration"
                                            >
                                                {{
                                                    duration === 12
                                                        ? '1 an'
                                                        : duration === 24
                                                        ? '2 ans'
                                                        : `${duration} mois`
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Budget mensuel <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input
                                                id="budget_mensuel"
                                                v-model="form.budget_mensuel"
                                                type="number"
                                                name="budget_mensuel"
                                                class="form-control"
                                                required=""
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text">€</span>
                                            </div>
                                        </div>
                                        <p v-if="errors.hasOwnProperty('budget_mensuel')" class="small text-danger">
                                            {{ errors.budget_mensuel.join(' ') }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Brief Client</label>
                                    <textarea v-model="form.brief_client" class="form-control" />
                                </div>
                            </div>

                            <div v-if="urlValid" class="col-12 col-md-8 mb-3">
                                <NewCampaignUrl ref="newKeywords" :url="form.url" :show-all="false" />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div
                                v-if="this.form.duree && this.form.budget_mensuel"
                                class="col-12 col-md-8 row mx-auto mt-4"
                            >
                                <b-checkbox required v-model="acceptCgu">
                                    <div v-html="condition" />
                                </b-checkbox>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12 col-md-8 row justify-content-end mx-auto mt-4">
                                <b-button
                                    icon-left="plus-circle"
                                    class="is-warning ml-auto"
                                    type="submit"
                                    :disabled="acceptCgu === false || !urlValid"
                                    @click="newAccompagnement"
                                >
                                    Créer un nouvel accompagnement
                                </b-button>
                            </div>
                        </div>
                    </form>
                </section>
            </section>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        title: 'mes-accompagnements.ask',
        name: 'AccompagnementNew',
        data() {
            return {
                errors: [],
                groups: [],
                durations: [],
                users: [],
                userSelected: null,
                emailChoosen: '',
                acceptCgu: false,
                form: {
                    duree: null,
                    budget_mensuel: null,
                    accompagnant_id: null,
                    accompagnant2_id: null,
                    groupe_id: 1
                },

                isLoading: false,
                preloading: false,
                isFetching: false,

                config: null
            }
        },
        computed: {
            urlValid() {
                let url

                try {
                    url = new URL(this.form.url || '')
                } catch (_) {
                    return false
                }

                return this.form.url && (url.protocol === 'http:' || url.protocol === 'https:')
            },
            condition() {
                return this.config
                    .replace('[month]', this.form.duree)
                    .replace('[monthly]', this.form.budget_mensuel)
                    .replace('[total]', this.form.budget_mensuel * this.form.duree)
            }
        },

        created() {
            this.getConfigByName({ name: 'html_config_accompagnement_modal' })
                .then(config => (this.config = config))
                .catch(e => console.error(e))
            this.getData()
        },
        methods: {
            ...mapActions('config', ['getConfigByName']),
            getData: function () {
                this.preloading = true

                var groups = this.axios
                    .get(`/campaigns/create`)
                    .then(response => {
                        this.groups = response.data.groups
                        this.durations = response.data.durations
                        this.userSelected = response.data.user
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })

                Promise.all([groups]).then(() => {
                    this.preloading = false
                })
            },

            newAccompagnement() {
                this.errors = []

                if (!this.userSelected || !this.userSelected.hasOwnProperty('id')) {
                    this.snackNotif('Utilisateur invalide')
                    return
                }

                this.isLoading = true

                let formData = new FormData()

                for (const [key, value] of Object.entries(this.form)) {
                    if (value == null) {
                        formData.append(key, '')
                    } else {
                        formData.append(key, value)
                    }
                }

                formData.append('keywords', JSON.stringify(this.$refs.newKeywords.getCheckedRows()))
                formData.append('user_id', JSON.stringify(this.userSelected.id))

                this.axios
                    .post(`/campaigns`, formData)
                    .then(() => {
                        this.snackNotif('Accompagnement créé avec succès !')
                        window.location.href = '/campaigns'
                        this.resetForm()
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.snackNotif('Formulaire incorrect', 'is-danger')
                            this.errors = error.response.data.errors
                        } else {
                            this.snackNotif(error.response.data.message, 'is-danger')
                        }

                        console.error(error)
                    })
                    .finally(() => (this.isLoading = false))
            },

            newKeyword() {
                this.form.keywords.push({})
            },

            loadRefDomains() {
                this.loadRefDomains = true
            },

            resetForm() {
                this.users = []
                this.userSelected = null
                this.emailChoosen = ''
                this.form = {
                    duree: null,
                    accompagnant_id: null,
                    accompagnant2_id: null,
                    groupe_id: null
                }
            }
        }
    }
</script>
